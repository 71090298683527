import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { EventAdminGetEventOutput, UserDataType } from './types';

interface EventAdminDevTools {
  hidePreview: boolean;
}

export const eventDataAtom = atom<EventAdminGetEventOutput | null>(null);

export const eventDataModifiedAtom = atom(false);
export const showRegistrationsModalAtom = atom(false);
export const showDuplicateModalAtom = atom(false);

export const showChangeLogModalAtom = atom(false);
export const saveBeforeActionDialogIsOpenAtom = atom(false);

export const userDataAtom = atom<UserDataType | null>(null);
export const saveButtonDisabledAtom = atom<boolean>(false);
export const eventUpdatedBySomeoneElseAtom = atom(false);
export const eventSavedTimestampAtom = atom<Date | null>(null);

export const previewEventVisibleAtom = atom(true);
export const globalCssEditorOpenAtom = atom(false);
export const globalCssEditorTabIndexAtom = atom<0 | 1>(0);
export const cssDataAtom = atom('');
export const onSiteCssDataAtom = atom('');
export const standAloneChatViewCssDataAtom = atom('');
export const javascriptDataAtom = atom('');

export const currentViewersAtom = atom(0);

export const eventAdminDevToolsAtom = atomWithStorage<EventAdminDevTools>('eventAdminDevTools', {
  hidePreview: false,
});
export const openCmdkAtom = atom(false);

export const errorDetailsAtom = atom<string | null>(null);
