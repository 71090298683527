import { useAtomValue } from 'jotai';
import { useSearch } from '@tanstack/react-router';

import { errorDetailsAtom } from './EventAdmin/event-admin-state';

const oldVideosyncUrl: string = import.meta.env.BACKSTAGE_OLD_VIDEOSYNC_URL;
const adminUrl = oldVideosyncUrl + '/admin#/main/all';

export const ErrorPage = () => {
  const errorDetails = useAtomValue(errorDetailsAtom);
  const { errorCode } = useSearch({ from: '/error' });

  return (
    <div className="flex min-h-screen items-center justify-center bg-gradient-to-b from-newgray-100 to-newgray-200 px-4">
      <div className="mx-auto max-w-lg space-y-6 text-center">
        <div className="relative">
          <div className="mb-4 text-red-500">
            <svg
              className="mx-auto h-24 w-24 animate-pulse"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <h1 className="mb-2 text-3xl font-bold text-newgray-800">Oops! Something Went Wrong</h1>
        </div>

        <div className="rounded-lg bg-white/80 p-6 shadow-lg backdrop-blur-sm">
          <p className="text-newgray-00">We encountered an unexpected error. Our team has been notified.</p>
          {errorDetails && (
            <div className="mt-4 flex flex-col gap-2 rounded-md bg-newgray-200 p-4">
              <h4>{errorCode}</h4>
              <p className="font-mono break-words text-sm text-newgray-600">{errorDetails}</p>
            </div>
          )}
        </div>

        <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
          <a
            href={adminUrl}
            className="inline-flex transform items-center rounded-lg bg-newgreen-400 px-6 py-3 text-base font-medium text-white transition duration-200 hover:scale-105 hover:bg-newgreen-500"
          >
            <svg className="mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              />
            </svg>
            Back to start
          </a>
        </div>

        <div className="mt-8 flex flex-col items-center text-sm text-newgray-600">
          If this issue persists, please contact our support team.{' '}
          <a href="mailto:support@videosync.fi" className="font-semibold text-newgray-800 hover:underline">
            support@videosync.fi
          </a>
        </div>
      </div>
    </div>
  );
};
